import React, { useState } from "react";
import { Tabs } from "@bluesilodev/timhutcomponents";

import { formatDateIndEng } from "utils/common/generateDateRange";
import ShiftReplacementAll from "./tabShiftReplacement";
import ExceedingTime from "./tabExceedingTime";

function ReplacementConflickData({
  lengthConflickData,
  lengthEmployee,
  conflickDate,
  locationName,
  firstName,
  lastName,
  backgroundColorShift,
  jobPosition,
  startTime,
  endTime,
  dataConflick,
}) {
  const [menuTab, setMenuTab] = useState(1);

  // dataConflick,
  // filterEmployee,
  // filteringLocation,

  // TO PROPS

  return (
    <div>
      {/* MENU TAB */}
      <div className="flex gap-1 rounded-md p-1 ">
        <Tabs
          options={[
            {
              value: <div className="w-[177px] ">Overlapping Shift</div>,
              id: 1,
            },
            {
              value: <div className="w-[177px]">Shift Replacements</div>,
              id: 2,
            },
            {
              value: <div className="w-[177px]">Exceeding Time</div>,
              id: 3,
            },
          ]}
          value={menuTab}
          onClick={(val) => {
            setMenuTab(val);
          }}
        />
      </div>

      <div>
        {menuTab === 1 && (
          <div className="mt-4 border-[1px] border-gray-300 rounded-md p-4">
            <h1 className="text-secondary font-semibold">
              {lengthConflickData + lengthEmployee} Overlapping Shifts on{" "}
              {formatDateIndEng(conflickDate)}
            </h1>

            <p className="font-semibold">
              {locationName} {">"} {firstName + " " + lastName}
            </p>

            <div className="flex flex-wrap mt-3 gap-5">
              <div className="h-[64px] flex justify-center items-center border-[1px] border-gray-300 p-1">
                <div
                  style={{
                    backgroundColor: backgroundColorShift,
                  }}
                  className="w-[210px] h-[56px] rounded-md flex flex-col justify-center items-center"
                >
                  <h1 className="font-semibold">{jobPosition}</h1>
                  <p>{startTime + " - " + endTime}</p>
                </div>
              </div>

              {/* MAP ALL DATA CONFLICK  */}

              {dataConflick.length > 0 &&
                dataConflick.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="h-[64px] flex justify-center items-center border-[1px] border-gray-300 p-1"
                    >
                      <div
                        style={{
                          backgroundColor: item?.shiftDetails?.color,
                        }}
                        className="w-[210px] h-[56px] rounded-md flex flex-col justify-center items-center"
                      >
                        <h1 className="font-semibold">{item?.jobPosition}</h1>
                        <p>
                          {item?.shiftDetails?.startTime +
                            " - " +
                            item?.shiftDetails?.endTime}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {menuTab === 2 && <ShiftReplacementAll />}

        {menuTab === 3 && <ExceedingTime />}
      </div>
    </div>
  );
}

export default ReplacementConflickData;
