import React from "react";
import { TimeCircle } from "assets/icons";

function TabExceedingTime({
  fullName,
  durationHour,
  role,
  linkImage,
  location,
  allDuration,
}) {
  function formatDateRange(punchIn, punchOut) {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const punchInDate = new Date(punchIn);
    const punchOutDate = new Date(punchOut);

    const formattedPunchIn = punchInDate.toLocaleDateString("en-US", options);
    const formattedPunchOut = punchOutDate.toLocaleDateString("en-US", options);

    const isSameMonth = punchInDate.getMonth() === punchOutDate.getMonth();
    return isSameMonth
      ? `${punchInDate.getDate()}-${punchOutDate.getDate()} ${punchInDate.toLocaleDateString(
          "en-US",
          { month: "long" }
        )} ${punchInDate.getFullYear()}`
      : `${formattedPunchIn} - ${formattedPunchOut}`;
  }

  return (
    <div className="mt-4 border-[1px] border-gray-300 rounded-md p-4">
      {allDuration.map((item) => {
        // GENERATE DATE
        const punchIn = new Date(item?.punchIn);
        const punchOut = new Date(item?.punchOut);

        return (
          <div className="font-semibold">
            Exceeding Time {item?.overTimeDuration} Hours per Week on{" "}
            {formatDateRange(punchIn, punchOut)}
          </div>
        );
      })}
      <p className="font-semibold">
        {location} {">"}{" "}
        {role?.map((item) => {
          return <span>{item}</span>;
        })}
      </p>

      <div className="flex items-center gap-3 mt-3">
        <img
          src={linkImage}
          alt="img1"
          className="w-[40px] h-[40px] rounded-full"
        />
        <div>
          <h1>{fullName}</h1>
          <div className="flex gap-1 items-center">
            {" "}
            <TimeCircle className="text-red-500" />{" "}
            <p className="text-red-500">{durationHour} Hours / Week</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabExceedingTime;
