import * as yup from 'yup'
import { validateTimeRange } from 'utils/common/converTime';

export const detailShiftSchema = yup.object().shape({
    shiftName: yup.string().required("Shift Name is Required!"),
    startTime: yup.string().required("Start Time is Required").test("startTime", "Start Time must be less than End Time", (val, context) => {
        const endTime = context.parent.endTime;

        if (val && endTime) {

            const [startHour, startMinute] = val.split(":").map(Number);
            const [endHour, endMinute] = endTime.split(":").map(Number);

            if (startHour > endHour || (startHour === endHour && startMinute >= endMinute)) {
                return false;
            }
        }
        return true;

    }),
    endTime: yup.string().required("End Time is Required").test("endTime", "End Time must be greater than Start Time ", (val, context) => {
        const startTime = context.parent.startTime;

        if (val && startTime) {

            const [endHour, endMinute] = val.split(":").map(Number);
            const [startHour, startMinute] = startTime.split(":").map(Number);

            if (endHour < startHour || (endHour === startHour && endMinute <= startMinute)) {
                return false;
            }
        }
        return true;
    }),
    startBreakTime: yup.string().required("Start Break Time is Required").test("Start Break Time", "ReArrange Your Start Break Time is < End Break Time and > Start Time", (val, context) => {
        const endBreakTime = context.parent.endBreakTime;

        const startTime = context.parent.startTime;
        const endTime = context.parent.endTime;

        if (val && endBreakTime && startTime && endTime) {

            // const [startHour, startMinute] = val.split(":").map(Number);
            // const [endHour, endMinute] = endBreakTime.split(":").map(Number);

            // if (startHour > endHour || (startHour === endHour && startMinute >= endMinute)) {
            //     return false;
            // }

            return validateTimeRange(val, endBreakTime, startTime, endTime)
        }
        // return true;

    }),
    endBreakTime: yup.string().required("End Break Time is Required").test("End Break Time", "Rearrange your time is > Start Break Time and < End Time", (val, context) => {
        const startBreakTime = context.parent.startBreakTime;
        const startTime = context.parent.startTime;
        const endTime = context.parent.endTime;

        if (val && startBreakTime && startTime && endTime) {

            // const [endHour, endMinute] = val.split(":").map(Number);
            // const [startHour, startMinute] = startTime.split(":").map(Number);

            // if (endHour < startHour || (endHour === startHour && endMinute <= startMinute)) {
            //     return false;
            // }
            return validateTimeRange(startBreakTime, val, startTime, endTime)
        }
        // return true;
    }),
    repeatDate: yup
        .array()
        .of(yup.date().required())
        .test(
            "dateRange",
            "Please arrange your Repeat start date and Repeat end date",
            (val) => {
                return (
                    val && val[0] && val[1] && val[0].getTime() !== val[1].getTime()
                );
            }
        )
        .required("Date range is required")
        .min(2, "Date range is required"),

    repeatBy: yup.object().shape({
        repeatType: yup.string().required('Repeat Type is Required'),
        repeatValue: yup
            .mixed()
            .test(
                "inputRepeatValue",
                "The value is not as expected",
                (val, ctx) => {
                    const repeatType = ctx.parent.repeatType;

                    if (val.length === 0 && repeatType === "Weekly") {
                        return false;
                    } else if (val <= 0 && repeatType === "Daily") {
                        return false;
                    } else if (val <= 0 && repeatType === "Monthly") {
                        return false;
                    }
                    return true;
                }
            ),
    }),
    employees: yup
        .array()
        .of(
            yup.object().shape({
                employeesNeeded: yup
                    .number()
                    .test(
                        "employees-neded",
                        "Please Increase Number Your Employee Needed",
                        (val) => val > 0
                    ),
                jobPosition: yup.string().required("Job Position is Required"),
            })
        )
        .test(
            "unique-jobPosition",
            null, // Leave this null; we handle messages individually for each field
            (employees, context) => {
                const jobPositions = employees.map((emp) => emp.jobPosition);
                const duplicates = jobPositions.filter(
                    (pos, index) => jobPositions.indexOf(pos) !== index
                );

                if (duplicates.length > 0) {
                    // Collect errors by creating a list of createError calls
                    const errors = duplicates.map((duplicate) => {
                        const index = jobPositions.indexOf(duplicate);
                        return context.createError({
                            path: `employees[${index}].jobPosition`,
                            message: "Job Position must be unique",
                        });
                    });

                    // Return the first error Yup encounters (since Yup doesn't handle arrays of errors)
                    return errors[0];
                }

                return true;
            }
        )

})
