import React, { useState } from "react";
import {
  CheckBox,
  InputNumber,
  InputSelect,
} from "@bluesilodev/timhutcomponents";

function RulesComponent({
  availability,
  setAvailability,
  leavesEmployee,
  overTimeLimit,
  setLeavesEmployee,
  relevantJobPosition,
  setRelevantJobPosition,
  setOverTimeLimit,
  // Staff priority
  staffPriority,
  setStaffPriority,
  setValStaffPriority,
  valStaffPriority,
  // GAP HOUR
  setMinXhour,
  minXHour,
  setValMinXHour,
  valMinXHour,
  // CONSECUTIVE
  maxConsecutive,
  numberMaxConsecutive,
  setNumMaxConsecutive,
  setMaxConsecutive,
}) {
  return (
    <div className="w-full">
      <h1 className="font-bold">Rules </h1>
      <div className="flex flex-col gap-3">
        <div className="flex w-full">
          <CheckBox
            value={availability}
            onClick={() => {
              setAvailability(!availability);
            }}
            nameInput={"availability"}
          />
          <div className="mt-2 w-full">
            <h1 className="font-semibold">Availability</h1>
            <p>
              Shifts will not be assigned to employee who did not indicate
              availability. The system will account for both shift and day
              availability's.
            </p>
          </div>
        </div>

        <div className="flex">
          <CheckBox
            value={leavesEmployee}
            onClick={() => {
              setLeavesEmployee(!leavesEmployee);
            }}
            nameInput={"leavesEmployee"}
          />
          <div className="mt-2">
            <h1 className="font-semibold">Leaves</h1>
            <p>
              Shifts will not be assigned to employee who have existing approved
              or assigned leaves.
            </p>
          </div>
        </div>

        <div className="flex">
          {/* Shift tidak akan diberikan kepada karyawan yang tidak memiliki a
              posisi pekerjaan relevan yang dibutuhkan. */}
          <CheckBox
            value={relevantJobPosition}
            onClick={() => {
              setRelevantJobPosition(!relevantJobPosition);
            }}
            nameInput={"relevantJobPosition"}
          />
          <div className="mt-2">
            <h1 className="font-semibold">Job Positions</h1>
            <p>
              Shifts will not be assigned to employee who does not have a
              relevant job positions required.
            </p>
          </div>
        </div>

        <div className="flex">
          <CheckBox
            value={overTimeLimit}
            onClick={() => {
              setOverTimeLimit(!overTimeLimit);
            }}
            nameInput={"overTimeLimit"}
          />
          <div className="mt-2">
            <h1 className="font-semibold">Overtime Limits</h1>
            <p>
              Employee will not be assigned more than their set OT limits hours
              per day / per week.
            </p>
          </div>
        </div>

        <div className="flex w-full">
          <CheckBox
            value={maxConsecutive}
            onClick={() => {
              setMaxConsecutive(!maxConsecutive);
            }}
            nameInput={"maxConsecutive"}
          />
          <div className="mt-2 w-full">
            <h1 className="font-semibold">
              Allow a maximum of <span className="text-secondary">X</span>{" "}
              consecutive days in a row in a week
            </h1>
            <div className="flex items-center mt-1  gap-3">
              <div className="w-[30%]">
                <InputNumber
                  name="quantity"
                  label="Consecutive days in a row in a week"
                  value={numberMaxConsecutive}
                  setFieldValue={(name, value) => setNumMaxConsecutive(value)}
                  onChange={(val) => {
                    return setNumMaxConsecutive(val);
                  }}
                  disable={!maxConsecutive}
                />
              </div>
              <p>
                Employee will not be assigned for{" "}
                <span className="text-secondary">
                  {numberMaxConsecutive > 0 ? numberMaxConsecutive : 0}
                </span>{" "}
                consecutive days in a row in a week.
              </p>
            </div>
          </div>
        </div>

        <div className="flex w-full">
          <CheckBox
            value={minXHour}
            onClick={() => {
              setMinXhour(!minXHour);
            }}
            nameInput={"minXHour"}
          />
          <div className="mt-2 w-full">
            <h1 className="font-semibold">
              Ensure a minimum of X hours between back-to-back shifts. Apply
              this to all shifts that end after time on the first day
            </h1>
            <div className="flex items-center mt-1  gap-3">
              <p>Employee Will have a </p>
              <div className="w-[20%]">
                <InputSelect
                  disabled={!minXHour}
                  defaultValue={{
                    label: "5 hours",
                    value: 5,
                  }}
                  options={[
                    {
                      label: "5 hours",
                      value: 5,
                    },
                    {
                      label: "10 hours",
                      value: 10,
                    },
                    {
                      label: "15 hours",
                      value: 15,
                    },
                  ]}
                  title={"Hours"}
                  value={valMinXHour}
                  onChange={(e) => setValMinXHour(Number(e.target.value))}
                />
              </div>
              <p>
                gap, between a previous day’s shifts. If it ends after{" "}
                <span className="text-secondary">
                  {valMinXHour ? valMinXHour : "X"} Hour
                </span>{" "}
                and the next day’s shift
              </p>
            </div>
          </div>
        </div>

        <div className="flex w-full">
          <CheckBox
            value={staffPriority}
            onClick={() => {
              setStaffPriority(!staffPriority);
            }}
            nameInput={"staffPriority"}
          />
          <div className="mt-2 w-full">
            <h1 className="font-semibold">Staff Priority</h1>
            <div className="flex flex-col mt-1  gap-3">
              <p>
                Pick the best employee, based on employe’s overall performance.
              </p>
              <div className="w-[30%]">
                <InputSelect
                  title={"Performace Metrics"}
                  defaultValue={{
                    label: "Lower Absenteeism rate",
                    value: "Lower Absenteeism rate",
                  }}
                  options={[
                    {
                      label: "Lower Absenteeism rate",
                      value: "Lower Absenteeism rate",
                    },

                    {
                      label: "Lower Lateness rate",
                      value: "Lower Lateness rate",
                    },
                  ]}
                  disabled={!staffPriority}
                  value={valStaffPriority}
                  onChange={(e) => setValStaffPriority(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RulesComponent;
