import moment from "moment";
import { formatDate } from "./generateDateRange";

function parseTime(timeString) {
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);
  return date;
}

export function generateTime(startTime, endTime) {
  const start = parseTime(startTime);
  const end = parseTime(endTime);

  // Calculate the difference in milliseconds
  const diffMs = end - start;

  // Convert the difference to hours and minutes
  const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  const resulDif = diffHours < 0 ? 0 : diffHours;
  return {
    hours: resulDif,
    minutes: diffMinutes,
  };
}

export const generateFilterBySelect = (selectFilterBy) => {
  //   if (customfromDate && customtoDate) {
  //     return {
  //       $gt: new Date(moment(customfromDate).utc().startOf("day").toISOString()),
  //       $lt: new Date(moment(customtoDate).utc().endOf("day").toISOString()),
  //     };
  //   }

  if (selectFilterBy === "Today") {
    moment.locale("id"); // Set locale ke bahasa Indonesia

    return {
      startTime: formatDate(new Date()),
      endTime: formatDate(new Date()),
    };
  }
  const now = moment().utc();

  if (selectFilterBy === "Past Week") {
    const lastWeek = now.clone().subtract(7, "days").startOf("day");

    const nowTime = formatDate(new Date());
    const parseLastWeek = formatDate(lastWeek._d);

    return {
      startTime: parseLastWeek,
      endTime: nowTime,
    };
  }

  if (selectFilterBy === "Past 1 Month") {
    const oneMonthAgo = now.clone().subtract(1, "months").startOf("day");

    const nowTime = formatDate(new Date());
    const parseOneMothAgo = formatDate(oneMonthAgo._d);

    return {
      startTime: parseOneMothAgo,
      endTime: nowTime,
    };
  }

  if (selectFilterBy === "Past 3 Month") {
    const threeMonthsAgo = now.clone().subtract(3, "months").startOf("day");

    const nowTime = formatDate(new Date());
    const parseThreeAgo = formatDate(threeMonthsAgo._d);

    return {
      startTime: parseThreeAgo,
      endTime: nowTime,
    };
  }

  // Default return null if none of the conditions matched
  return null;
};
