import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import customAxios from "utils/axios";
import { alertSuccess, alertError } from "./alert";

const API = `${process.env.REACT_APP_API_SCHEDULE}/api/shift`;

export const useGetAllShift = ({ onSuccess, onError }) => {
  const getAllShift = async () => {
    const resp = await customAxios.get(`${API}`);
    return resp.data;
  };

  return useQuery({
    queryKey: ["getAllShift"],
    queryFn: getAllShift,
    enabled: true,
    onSuccess,
    onError,
  });
};

export const useCreateShift = () => {
  const queryClient = useQueryClient();

  const createShift = async (body) => {
    const resp = await customAxios.post(`${API}`, body);

    return resp.data;
  };

  return useMutation({
    mutationFn: createShift,
    onSuccess: () => {
      queryClient.invalidateQueries("getAllShift");

      alertSuccess("Create Shift Success");
    },
    onError: (err) => {
      alertError("Something Wrong create Shift!");
    },
  });
};

export const useUpdateShift = (uId, { onSuccess, onError }) => {
  const updateShift = async (body) => {
    const resp = await customAxios.put(`${API}/${uId}`, body);
    return resp.data;
  };

  return useMutation({
    mutationFn: updateShift,
    onSuccess,
    onError,
  });
};

export const useDeleteShift = (uId, { onSuccess, onError }) => {
  const deleteShift = async () => {
    const resp = await customAxios.delete(`${API}/${uId}`);
    return resp.data;
  };

  return useMutation({
    mutationFn: deleteShift,
    onSuccess,
    onError,
  });
};

export const useDownloadExcel = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const res = await customAxios.post(`${API}/excel`, body, {
        responseType: "blob",
      });
      return res.data;
    },
    onSuccess,
    onError,
  });
};

export function useExceedingTime(props = {}) {
  const { fromDate, toDate } = props;

  const exceed = async () => {
    const res = await customAxios(
      `${API}/exceedingtime?fromDate=${fromDate}&toDate=${toDate}`
    );

    return res.data;
  };

  return useQuery({
    queryKey: ["overTime"],
    queryFn: exceed,
    onError: (err) => {
      console.log("ERR : ", err);
    },
  });
}
