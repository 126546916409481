import React from "react";
import { useGetAllEmployee, useGetAllLocation } from "services/employeeApi";
import { useReplacementConflick } from "context/ReplacementConflickProvider";
import ReplacementConflickData from "shared/showConflick";

function ConflictOneData() {
  const { dataConflick } = useReplacementConflick();

  // location base on locationId
  const { data: locationData, isLoading: loadingLocation } =
    useGetAllLocation();

  // employee base on shiftId
  const { data: employeeData, isLoading: loadingEmployee } =
    useGetAllEmployee();

  if (loadingLocation || loadingEmployee) {
    return <h1>Loading...</h1>;
  }

  //  FILTERING LOCATION
  const filteringLocation =
    locationData.data.length > 0 &&
    locationData.data.filter((item) => {
      return item.uId === dataConflick?.shiftDetails?.locationId;
    });

  //   FILTERING EMPLOYEE
  const employee =
    employeeData.data.length > 0 &&
    employeeData.data.flatMap((item) => {
      return item.employee;
    });

  const filterEmployee =
    employee.length > 0 &&
    employee.filter((item) => item.uId === dataConflick.userId);

  /* 
    lengthConflickData = dataConflick.conflickData.length 
    lengthEmployee = filterEmployee.length
    conflickDate = dataConflick.date
    locationName= filteringLocation[0].locationName
    firstName = filterEmployee[0].firstName
    lastName = filterEmployee[0].lastName
    backgroundColorShift = dataConflick.shiftDetails.color
    jobPosition = dataConflick.jobPosition
    startTime = dataConflick.shiftDetails.startTime
    endTime = dataConflick.shiftDetails.endTime
  */

  return (
    <>
      <ReplacementConflickData
        lengthConflickData={dataConflick.conflickData.length}
        lengthEmployee={filterEmployee.length}
        conflickDate={dataConflick.date}
        locationName={filteringLocation[0].locationName}
        firstName={filterEmployee[0]?.firstName}
        lastName={filterEmployee[0]?.lastName}
        backgroundColorShift={dataConflick?.shiftDetails?.color}
        jobPosition={dataConflick.jobPosition}
        startTime={dataConflick?.shiftDetails?.startTime}
        endTime={dataConflick?.shiftDetails?.endTime}
        dataConflick={dataConflick.conflickData}
      />
    </>
  );
}

export default ConflictOneData;
