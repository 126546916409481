import React, { useState } from "react";
import { RadioButton, Button } from "@bluesilodev/timhutcomponents";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import { useAutoAssign } from "services/autoAssignService";
import { formatDate } from "utils/common/generateDateRange";

import { CloseRound, MagicSvg } from "assets/icons";
import RulesComponent from "./rulesComponent";
import SelectedEmployee from "./selectedEmployee";
import { alertSuccess, alertError } from "services/alert";

function AutoAssignShift({ setShowAutoAssign }) {
  const [showSelectedEmploye, setShowSelectedEmployee] = useState(
    "All Employee in this Locations"
  );
  const queryClient = useQueryClient();

  const { dataDate, locationState } = useSelector(
    (state) => state.employeeView
  );

  const fromDate = dataDate.length > 0 && formatDate(dataDate[0]);
  const toDate =
    dataDate.length > 0 && formatDate(dataDate[dataDate.length - 1]);

  const [availability, setAvailability] = useState(false);
  const [leavesEmployee, setLeavesEmployee] = useState(false);
  const [relevantJobPosition, setRelevantJobPosition] = useState(false);
  const [overTimeLimit, setOverTimeLimit] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  const [minXHour, setMinXhour] = useState(false);
  const [valMinXHour, setValMinXHour] = useState(5);

  const [valStaffPriority, setValStaffPriority] = useState(
    "Lower Absenteeism rate"
  );
  const [staffPriority, setStaffPriority] = useState(false);

  const [maxConsecutive, setMaxConsecutive] = useState(false);
  const [numberMaxConsecutive, setNumMaxConsecutive] = useState(1);

  // SELECT EMPLOYEE
  const [selectedRows, setSelectedRows] = useState([]);

  // FILL BODY
  // fromDate,
  // toDate,
  // isAllEmployees,
  // selectedEmployees,

  const { mutate: postAutoAssign, isLoading: loadingAutoAssign } =
    useAutoAssign({
      onSuccess: () => {
        queryClient.invalidateQueries("allAutoAssign");

        setShowAutoAssign(false);
        alertSuccess("Auto Assign Success Success");
      },
      onError: (err) => {
        alertError("There is Something Wrong");
        console.log("ERR : ", err);
      },
    });

  // checkAvailability = Shift tidak akan diberikan kepada karyawan yang tidak menunjukkan ketersediaannya. Sistem akan memperhitungkan ketersediaan shift dan hari.,

  // checkLeave = shift tidak akan diberikan kepada karyawan yang telah mendapat cuti yang disetujui atau ditugaskan,

  // checkJobPosition = shift tidak akan diberikan kepada karyawan yang tidak memiliki posisi pekerjaan relevan yang dibutuhkan.,

  // maxConsecutiveDaysInWeek = Karyawan tidak akan ditugaskan lebih dari batas jam lembur yang ditetapkan per hari / per minggu. ,

  // gapInShifts = Pastikan minimal X jam antara shift berturut-turut. Terapkan ini pada semua shift yang berakhir setelah waktu pada hari pertama Karyawan Akan mempunyai jeda, antara shift hari sebelumnya. Jika berakhir setelah pukul 22.00 dan shift hari berikutnya,

  // staffPriority = Pilih karyawan terbaik, berdasarkan kinerja karyawan secara keseluruhan. ,

  const handleSubmit = (e) => {
    e.preventDefault();

    const baseOnSelectEmployee = {
      // WAJIB ADA
      fromDate,
      toDate,
      selectedEmployees: selectedRows,
      locationId: locationState,

      checkAvailability: availability,
      checkLeave: leavesEmployee,
      checkJobPosition: relevantJobPosition,
      maxConsecutiveDaysInWeek:
        maxConsecutive && numberMaxConsecutive > 0 && numberMaxConsecutive,
      gapInShifts: minXHour && valMinXHour,
      staffPriority: staffPriority && valStaffPriority,
    };

    const selectBaseOnLocation = {
      fromDate,
      toDate,
      isAllEmployees: true,
      locationId: locationState,

      checkLeave: leavesEmployee,
      checkJobPosition: relevantJobPosition,
      maxConsecutiveDaysInWeek:
        maxConsecutive && numberMaxConsecutive > 0 && numberMaxConsecutive,
      gapInShifts: minXHour && valMinXHour,
      staffPriority: staffPriority && valStaffPriority,
    };

    if (selectedRows.length > 0) {
      // console.log("DATA : ", baseOnSelectEmployee);
      postAutoAssign(baseOnSelectEmployee);
    } else {
      // console.log("DATA : ", selectBaseOnLocation);
      postAutoAssign(selectBaseOnLocation);
    }
  };

  if (loadingAutoAssign) {
    return <div>Loading...</div>;
  }

  const statusButton =
    // Kondisi wajib
    ((locationState || selectedRows.length > 0) &&
      // Kondisi optional (minimal satu true)
      (availability ||
        leavesEmployee ||
        relevantJobPosition ||
        overTimeLimit ||
        (minXHour && valMinXHour) ||
        (staffPriority &&
          valStaffPriority &&
          valStaffPriority === "Lower Absenteeism rate") ||
        valStaffPriority === "Lower Lateness rate")) ||
    (maxConsecutive && numberMaxConsecutive > 0);

  return (
    <div>
      <h1 className="font-semibold text-[14px]">
        Auto Assign for <span className="text-[#DD7224]">14</span> Empty Shifts
      </h1>

      <div className="mt-5 ">
        <RadioButton
          onClick={(e) => {
            setShowSelectedEmployee(e.target.value);
          }}
          value={showSelectedEmploye}
          name={"bebas"}
          options={[
            {
              id: "All Employee in this Locations",
              label: "All Employee in this Locations",
            },
            {
              id: "Selected Employee only (10 Selected)",
              label: "Selected Employee only (10 Selected)",
            },
          ]}
        />
      </div>

      <div className="mt-5">
        {showSelectedEmploye === "Selected Employee only (10 Selected)" && (
          <SelectedEmployee
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            selectAll={selectAll}
            setSelectAll={setSelectAll}
          />
        )}

        <div className="mt-5">
          <RulesComponent
            availability={availability}
            leavesEmployee={leavesEmployee}
            overTimeLimit={overTimeLimit}
            relevantJobPosition={relevantJobPosition}
            setAvailability={setAvailability}
            setLeavesEmployee={setLeavesEmployee}
            setRelevantJobPosition={setRelevantJobPosition}
            setOverTimeLimit={setOverTimeLimit}
            // GAP HOUR
            minXHour={minXHour}
            setMinXhour={setMinXhour}
            setValMinXHour={setValMinXHour}
            valMinXHour={valMinXHour}
            // Consecutive day
            maxConsecutive={maxConsecutive}
            numberMaxConsecutive={numberMaxConsecutive}
            setMaxConsecutive={setMaxConsecutive}
            setNumMaxConsecutive={setNumMaxConsecutive}
            // STAFF PRIORITY
            setStaffPriority={setStaffPriority}
            staffPriority={staffPriority}
            setValStaffPriority={setValStaffPriority}
            valStaffPriority={valStaffPriority}
          />
        </div>
      </div>

      <div className="flex justify-center items-center w-full gap-5 mt-3">
        <Button
          onClick={() => {
            setShowAutoAssign(false);
          }}
          type={"button"}
          label={
            <div className="flex gap-2 items-center group transition-colors">
              <CloseRound className={"group-hover:text-white"} />
              <h1>Cancel</h1>
            </div>
          }
        />

        <Button
          type={"button"}
          onClick={handleSubmit}
          label={
            <div className="flex gap-2 items-center">
              <MagicSvg />
              <h1>Run Auto Assign</h1>
            </div>
          }
          disabled={!statusButton}
          style={"secondary"}
        />
      </div>
    </div>
  );
}

export default AutoAssignShift;
