import { isBefore, isEqual, addDays, format } from "date-fns";

// DIDALAM FOLDE COMMON UTILS

export function generateDateRange(startDate, endDate) {
  let dates = [];

  let currentDate = startDate;

  while (isBefore(currentDate, endDate) || isEqual(currentDate, endDate)) {
    dates.push(currentDate);
    currentDate = addDays(currentDate, 1);
  }

  return dates;
}

export function generateDateFromTo({ from, to }) {
  const startDate = new Date(from);
  const endDate = new Date(to);
  const dateArray = [];

  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    dateArray.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}

export function getCurrentWeekDates() {
  const today = new Date();
  const firstDayOfWeek = today.getDate() - today.getDay() + 1; // Setel hari Senin sebagai hari pertama
  const startOfWeek = new Date(today.setDate(firstDayOfWeek));
  const endOfWeek = new Date(today.setDate(startOfWeek.getDate() + 6));

  const dates = [];
  let currentDate = new Date(startOfWeek);

  while (currentDate <= endOfWeek) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
}

export const formatDateV2 = (dateString) => {
  const [day, month, year] = dateString.split("/");
  return `${year}-${month}-${day}`;
};

export function dateFormat(date) {
  return format(date, "dd/MM/yyyy");
}

// GENERATE TO LIKE Sunday, 4 Agustus 2024
export function formatDateIndEng(isoDateString) {
  const date = new Date(isoDateString);

  // Mendapatkan nama hari dalam bahasa Inggris
  const dayOptions = { weekday: "long" };
  const dayName = date.toLocaleDateString("en-US", dayOptions);

  // Mendapatkan tanggal, bulan, dan tahun dalam bahasa Indonesia
  const dateOptions = { day: "numeric", month: "long", year: "numeric" };
  const datePart = date.toLocaleDateString("id-ID", dateOptions);

  // Menggabungkan hasil menjadi format yang diinginkan
  const formattedDate = `${dayName}, ${datePart}`;

  return formattedDate;
}

export function formatDate(dateString) {
  // Buat objek Date dari string
  const date = new Date(dateString);

  // Dapatkan tahun, bulan, dan hari
  const year = date.getFullYear();
  // Tambahkan 1 pada bulan karena di JavaScript bulan dimulai dari 0
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // Gabungkan menjadi format YYYY-MM-DD
  return `${year}-${month}-${day}`;
}

export function formatDateSlash(dateString) {
  // Buat objek Date dari string
  const date = new Date(dateString);

  // Dapatkan tahun, bulan, dan hari
  const year = date.getFullYear();
  // Tambahkan 1 pada bulan karena di JavaScript bulan dimulai dari 0
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  // Gabungkan menjadi format YYYY-MM-DD
  return `${day}/${month}/${year}`;
}
