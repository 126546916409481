import React, { useState, useEffect } from "react";
import {
  DataTable,
  InputSelect,
  SearchBoxV2,
  CheckBox,
} from "@bluesilodev/timhutcomponents";

import {
  useGetAllLocation,
  useGetAllEmployee,
  useGetAllJobPosition,
  useGetAllDepartment,
} from "services/employeeApi";

function SelectedEmployee({
  setSelectedRows,
  selectedRows,
  selectAll,
  setSelectAll,
}) {
  const [selectLocation, setSelectLocation] = useState();
  const [searchEmployee, setSearchEmployee] = useState();
  const [submitSearch, setSubmitSearch] = useState("");
  const [selectJobPosition, setSelectJobPosition] = useState();
  const [selectDepartment, setSelectDepartment] = useState();

  const handleSelectAllChange = () => {
    if (selectAll) {
      // If already selected all, deselect all
      setSelectedRows([]);
    } else {
      // Select all employees
      setSelectedRows(allEmployee.map((emp) => emp.uId));
    }
    setSelectAll(!selectAll);
  };

  const handleRowSelectChange = (empId) => {
    // console.log("INCLUDES ID : ", selectedRows.includes(empId));

    if (selectedRows.includes(empId)) {
      // Deselect the employee
      setSelectedRows(selectedRows.filter((id) => id !== empId));
    } else {
      // Select the employee
      setSelectedRows([...selectedRows, empId]);
    }
  };

  const {
    data: dataEmployee,
    isLoading: loadingEmployee,
    isSuccess: successEmployee,
    refetch,
  } = useGetAllEmployee({
    firstName: submitSearch,
    locationId: selectLocation,
    jobPosition: selectJobPosition,
    department: selectDepartment,
  });

  useEffect(() => {
    if (successEmployee && dataEmployee) {
      refetch();
    }
  }, [submitSearch, selectLocation, dataEmployee, successEmployee, refetch]);

  const { data: locationData, isLoading: locationLoading } =
    useGetAllLocation();

  const { data: allJobPosition, isLoading: loadingJobPosition } =
    useGetAllJobPosition();

  const { data: allDepartment, isLoading: loadingDepartment } =
    useGetAllDepartment();

  if (
    loadingEmployee ||
    locationLoading ||
    loadingJobPosition ||
    loadingDepartment
  ) {
    return <div>Loading...</div>;
  }

  const allEmployee =
    dataEmployee.data.length > 0 &&
    dataEmployee.data.flatMap((item) => {
      // FILTER EMPLOYEE NON ADMIN
      const nonAdmin = item.employee.filter((filter) => {
        return !filter.role.includes("Admin");
      });

      return nonAdmin.map((emp) => ({
        ...emp,
        jobPosition: item.jobPosition,
      }));
    });

  const allLocation =
    locationData.data.length > 0 &&
    locationData.data.map((item) => {
      return {
        label: item.locationName,
        value: item.uId,
      };
    });

  const listAllJobPosition =
    allJobPosition?.data.length > 0 &&
    allJobPosition?.data.map((item) => {
      return {
        label: item.jobPosition,
        value: item.jobPosition,
      };
    });

  const listAllDeparment =
    allDepartment?.data?.length > 0 &&
    allDepartment?.data?.map((item) => {
      return {
        label: item.departmentName,
        value: item.departmentName,
      };
    });

  // const dataRow = [
  //   {
  //     select: false,
  //     employee: "jonny",
  //     location: "cafe halim",
  //     department: "operation",
  //     jobPosition: "supervisor",
  //   },
  // ];

  const dataColumn = [
    {
      id: "select",
      accessorFn: (row) => row,
      header: () => (
        <div>
          <CheckBox
            nameInput={"selectAll"}
            value={selectAll}
            onChange={handleSelectAllChange}
            borderColor={"white"}
          />
        </div>
      ),
      cell: (param) => {
        // console.log("value : ", param.getValue());
        const employee = param.getValue();
        const isSelected = selectedRows.includes(employee.uId);

        return (
          <div className="cursor-pointer w-fit">
            <CheckBox
              nameInput={`selectRow_${employee.uId}`}
              value={isSelected}
              onChange={() => handleRowSelectChange(employee.uId)}
              borderColor={"gray-400"}
            />
          </div>
        );
      },
      disableSorting: true,
    },

    {
      id: "employee",
      accessorFn: (row) => {
        return row.firstName;
      },
      header: () => <span>Employee</span>,
      enableSorting: true,
    },

    {
      id: "location",
      accessorFn: (row) => {
        const data = row?.locations[0]?.locationName;
        return data;
      },
      header: () => <span>Location</span>,
      enableSorting: true,
    },
    {
      id: "department",
      accessorFn: (row) => {
        return row?.userInformation?.employeementDetail?.departments;
      },
      header: () => <span>Department</span>,
      enableSorting: true,
    },
    {
      id: "jobPosition",
      accessorFn: (row) => row?.jobPosition,
      header: () => <span>Job Position</span>,
      enableSorting: true,
    },
  ];

  return (
    <div>
      <div className="flex gap-3">
        <InputSelect
          options={allLocation}
          title={"Locations"}
          value={selectLocation}
          onChange={(e) => setSelectLocation(e.target.value)}
        />
        <InputSelect
          options={listAllDeparment}
          title={"Department"}
          value={selectDepartment}
          onChange={(e) => setSelectDepartment(e.target.value)}
        />
        <InputSelect
          options={listAllJobPosition}
          title={"JobPositions"}
          value={selectJobPosition}
          onChange={(e) => setSelectJobPosition(e.target.value)}
        />

        <SearchBoxV2
          value={searchEmployee}
          onChange={(e) => {
            setSearchEmployee(e.target.value);
          }}
          submitSearch={() => {
            setSubmitSearch(searchEmployee);
            setSearchEmployee("");
          }}
          className={"h-[60px] w-full"}
          placeholder={"Search Employee"}
        />
      </div>

      <div className="mt-5">
        <DataTable columns={dataColumn} data={allEmployee} pagination={true} />
      </div>
    </div>
  );
}

export default SelectedEmployee;
