import React, { useState } from "react";
import {
  InputSelect,
  SearchBoxV2,
  Button,
} from "@bluesilodev/timhutcomponents";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

import { useRequestReplacement } from "services/replacementService";
import { setShowRequesReplacement } from "store/requestReplacementSlice";
import { formatDate, formatDateIndEng } from "utils/common/generateDateRange";

import { FiCheckCircle } from "assets/icons";
import { useGetAllEmployee } from "services/employeeApi";
import { UserAdd } from "assets/icons";
import { alertSuccess, alertError } from "services/alert";
import { useGetByIdAssign } from "services/assignShiftService";

function FindReplacementRequest({
  shiftData,
  uIdShift,
  setShowModalEditAssign,
}) {
  const [checkEmployee, setCheckEmployee] = useState("");
  const [employeeOne, setEmployeeOne] = useState([]);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [selectJobPosition, setSelectJobposition] = useState("");
  const [submitSearch, setSubmitSearch] = useState("");

  const { dataDate } = useSelector((state) => state.employeeView);

  const startDate = dataDate.length > 0 && dataDate[0];
  const endDate = dataDate.length > 0 && dataDate[dataDate.length - 1];

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  // GET BY ID ASSIGN SHIFT
  const { data: dataAssignShift, isLoading: loadingAssign } = useGetByIdAssign({
    uId: uIdShift,
  });

  // GET ALL EMPLOYEE
  const {
    data: employeeData,
    isLoading: loadingEmployee,
    isSuccess,
  } = useGetAllEmployee({
    firstName: submitSearch,
    jobPosition: selectJobPosition,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  });

  const { mutate: requestReplacement, isLoading: loadingRequesReplacement } =
    useRequestReplacement({
      onSuccess: () => {
        alertSuccess("Request Replacement Success");
        queryClient.invalidateQueries("getRequestReplacement");
        dispatch(setShowRequesReplacement(false));
      },
      onError: (err) => {
        alertError("Request Failed, Something Wrong!");
        console.log("Something Wrong !", err);
      },
    });

  if (!uIdShift) {
    dispatch(setShowRequesReplacement(false));
  }

  if (loadingEmployee || loadingRequesReplacement || loadingAssign) {
    return <div>Loading...</div>;
  }

  const employeeUid = shiftData?.employee?.uId;

  // ["jobposition"]
  const needJobPosition =
    dataAssignShift?.shift?.employees &&
    dataAssignShift?.shift?.employees?.map((item) => {
      return item.jobPosition;
    });

  // SELECT JOB BASE SHIFT NEED EMPLOYEE
  const selectAllJobs =
    (dataAssignShift?.shift.employees &&
      dataAssignShift?.shift?.employees?.map((shift) => {
        return {
          label: shift?.jobPosition,
          value: shift?.jobPosition,
        };
      })) ||
    [];

  // NEED NEW API FOR LIST JOB POSITION AND LIST DEPARTMENT

  const allEmployee = Array.isArray(employeeData?.data)
    ? employeeData?.data.flatMap((item) => {
        // FILTER EMPLOYEE NON ADMIN
        const nonAdmin = item.employee.filter((filter) => {
          const empJobposition =
            filter?.userInformation &&
            filter?.userInformation?.employeementDetail?.jobPosition;

          return (
            !filter.role.includes("Admin") &&
            filter.uId !== employeeUid &&
            needJobPosition.includes(empJobposition)
          );
        });

        return nonAdmin;
      })
    : [];

  const handleCheckEmployee = (item) => {

    if (Object.keys(item.leaveData).length === 0) {
      setEmployeeOne(item);
      setCheckEmployee(item.uId);
    }
  };

  const requestPostReplacement = () => {

    const dataReplacement = {
      assignShiftId: shiftData?.assignShift?.uId,
      previousUserId: shiftData?.employee?.uId,
      replacementUserId: employeeOne?.uId,
    };

    requestReplacement(dataReplacement);
  };

  return (
    <div className="">
      <p>Select a employees to replace you in this shift</p>
      <div className="flex justify-between gap-5 mt-2 ">
        <div className="w-[50%]">
          <InputSelect
            title={"Job Positions"}
            options={selectAllJobs}
            value={selectJobPosition}
            onChange={(e) => setSelectJobposition(e.target.value)}
          />
        </div>

        {/* <h1>{selectJobPosition}</h1> */}

        <div className="w-[90%] ">
          <SearchBoxV2
            value={searchEmployee}
            onChange={(e) => {
              setSearchEmployee(e.target.value);
            }}
            submitSearch={() => {
              setSubmitSearch(searchEmployee);
              setSearchEmployee("");
            }}
            className={"h-[60px]"}
            placeholder={"Search Employee"}
          />
        </div>
      </div>

      {employeeData.data.length === 0 && <div>Data Not Found!</div>}

      <div className="flex flex-col gap-3 mt-3">
        {allEmployee.length > 0 &&
          allEmployee.map((item, index) => {
            const empJobposition =
              item?.userInformation &&
              item.userInformation?.employeementDetail.jobPosition;

            const leaveTimes =
              Object.keys(item.leaveData).length > 0 &&
              item.leaveData?.leaveTimes[0];

            const whenDate = formatDateIndEng(leaveTimes?.date);

            return (
              <div
                key={index}
                onClick={() => handleCheckEmployee(item)}
                className={`${
                  checkEmployee === item.uId &&
                  Object.keys(item.leaveData).length === 0
                    ? "border-[1px] border-[#DD7224]"
                    : `${
                        Object.keys(item.leaveData).length === 0
                          ? "border-[1px] border-gray-300"
                          : "border-[1px] border-gray-300 bg-[#F2F4F5]"
                      }`
                } w-full h-[72px] rounded-md ${
                  Object.keys(item.leaveData).length === 0 &&
                  "hover:bg-[#FFD9BE] duration-300 transition-all hover:border-[#DD7224] cursor-pointer"
                } p-2 flex items-center justify-between select-none`}
              >
                <div className="flex gap-3 items-center">
                  <img
                    className="w-[40px] h-[40px] rounded-full"
                    src={`${item?.photo[0]?.link}`}
                    alt="igm1"
                  />
                  <div>
                    <h1>{item?.firstName}</h1>
                    <p>{empJobposition}</p>
                  </div>
                </div>

                {Object.keys(item.leaveData).length !== 0 && (
                  <div>
                    <h1 className="text-[#A3A3AB]">On Leave {whenDate}</h1>
                  </div>
                )}
                {checkEmployee === item.uId && <FiCheckCircle />}
              </div>
            );
          })}
      </div>

      <Button
        disabled={!checkEmployee && true}
        onClick={requestPostReplacement}
        label={
          <div
            className={`flex gap-3 items-center ${
              checkEmployee ? "text-white" : "text-black "
            }`}
          >
            <UserAdd />
            <h1>Select Employee</h1>
          </div>
        }
        style={"solid"}
        className={"mt-5 mb-3 w-full"}
      />
    </div>
  );
}

export default FindReplacementRequest;
