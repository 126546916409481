import React, { useState } from "react";
import {
  Button,
  ModalDialog,
  ModalConfirmation,
} from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useChangeBorder } from "context/changeBorder";
import { useSelector } from "react-redux";

import {
  useDeleteAllAssignShift,
  useGetAllDashboard,
} from "services/assignShiftService";
import { useGetAllConflickData } from "services/conflickDataApi";
import { formatDate } from "utils/common/generateDateRange";
import { alertSuccess, alertError } from "services/alert";

import {
  MagicSvg,
  FiTrash2,
  FiUpload,
  ErrorSvg,
  PaperUpload,
  FiFilePlus,
} from "assets/icons";
import FormCreateShift from "../formCreateShift/formCreateShift";
import AutoAssignShift from "pages/dashboard/employeeView/autoAssignShift";
import ConflickAllData from "pages/dashboard/employeeView/conflickAllData";
import ModalDownloadExcel from "./modalDownload.Excel";

function ButtonAllEmployeeView({ values, showConfirmPublish }) {
  const [showCreateShift, setShowCreateShift] = useState(false);
  const [showAutoAssign, setShowAutoAssign] = useState(false);

  const [confirmationDeleteAll, setConfirmationDeleteAll] = useState(false);
  const [showAllConflick, setShowAllConflick] = useState(false);

  // EXPORT EXCEL
  const [modalDownload, setShowModalDownload] = useState(false);

  const queryClient = useQueryClient();

  const { statusBorder } = useChangeBorder();

  const { currentRole } = useSelector((state) => state.loginSlice);

  const handleCreateShift = () => {
    setShowCreateShift(true);
  };

  const onCloseModal = () => {
    setShowCreateShift(false);
  };

  const closeAutoAssignModal = () => {
    setShowAutoAssign(false);
  };

  // AUTO ASSIGN
  const autoAssignShift = () => {
    setShowAutoAssign(true);
  };

  const { mutate: deleteAllAssignShift, isLoading: loadingDeleteAssignShift } =
    useDeleteAllAssignShift({
      onSuccess: () => {
        queryClient.invalidateQueries("getDashboard");
        setConfirmationDeleteAll(false);
        alertSuccess("Data Has Been Deleted!");
      },
      onError: (err) => {
        alertError("Something Wrong!");
        console.log("ERR : ", err);
      },
    });

  const fromDate = formatDate(values.date[0]);
  const toDate = formatDate(values.date[values.date.length - 1]);

  const { data: dataDashboard, isLoading: loadingDashboard } =
    useGetAllDashboard({
      fromDate,
      toDate,
    });

  const { data: conflickData, isLoading: loadingConflick } =
    useGetAllConflickData({
      fromDate,
      toDate,
    });

  // DELETE ALL DATA ASSIGN SHIFT BASE ON VALUES DATE
  const confirmDeleteAllAssign = () => {
    deleteAllAssignShift({
      fromDate: fromDate,
      toDate: toDate,
    });
  };

  if (loadingDeleteAssignShift || loadingDashboard || loadingConflick) {
    return <div>Loading...</div>;
  }

  // FILTER JIKA ADA ID CONFLICK DATA YANG SAMA DENGAN DI DASHBORD JANGAN MASUKKAN KEDALAM ARRAY
  // const findConflickData= dataDashboard.length > 0 && dataDashboard.filter(item => {

  // })

  // const filterDashboardData =
  //   dataDashboard.length > 0 &&
  //   dataDashboard.filter((item) => {
  //     return (
  //       item.conflickData &&
  //       item.conflickData.length === 0 &&
  //       item.isActive === true
  //     );
  //   });

  const filterConflick = conflickData.conflicts.flatMap((item) => {
    return item.conflictingAssignShifts;
  });

  const conflick = [
    {
      uId: "8cddfc28-b348-45eb-87bd-cc46e2c86cbe",
      isDeleted: false,
      isReplacement: false,
      jobPosition: "Front End",
    },
    {
      uId: "96d4a57e-557e-45f6-bf3b-60d629591f0a",
      isDeleted: false,
      isReplacement: false,
      jobPosition: "Front End",
    },
    {
      uId: "1bc75c9b-abd7-424a-9c74-4fead2045f70",
      isDeleted: false,
      isReplacement: false,
      jobPosition: "Front End",
    },
  ];

  const dashboard = [{}];

  const dataFilterEmployee =
    dataDashboard.length > 0 &&
    dataDashboard.map((item) => {
      const resultFilter =
        filterConflick.length > 0 &&
        filterConflick.filter((conf) => {
          return conf.shiftId !== item.shiftId;
        });

      return resultFilter;
    });

  const dataAll = [
    {
      label: "All Employee",
      value: "All Employee",
    },
  ];

  // const findConflickData =
  //   dataDashboard.length > 0 &&
  //   dataDashboard.forEach((item) => {
  //     const key = `${item?.shiftDetails?.startTime}-${item?.shiftDetails?.endTime}`;

  //     const seen = new Map();
  //     const result = [];

  //     if (seen.has(key)) {
  //       // if there is conflick data create property conflickData and push
  //       const existingItem = seen.get(key);
  //       existingItem.conflickData.push(item);
  //     } else {
  //       // if there is no conflick data just push
  //       item.conflickData = [];
  //       seen.set(key, item);
  //       result.push(item);
  //     }
  //   });

  // const filterByAllEmployee =
  //   filterDashboardData.length > 0 &&
  //   filterDashboardData.reduce((acc, curr) => {
  //     if (!acc.some((item) => item.uId === curr.employeeData.uId)) {
  //       acc.push({
  //         employeeName: curr.employeeData.firstName,
  //         uId: curr.employeeData.uId,
  //       });
  //     }
  //     return acc;
  //   }, []);

  // const listAllEmployee = [
  //   ...dataAll,
  //   ...(filterByAllEmployee && filterByAllEmployee.length > 0
  //     ? filterByAllEmployee.map((item) => ({
  //         label: item.employeeName,
  //         value: item.uId,
  //       }))
  //     : []),
  // ];

  const showModalDownload = () => {
    setShowModalDownload(true);
  };

  // SHOW ALL CONFLICK DATA
  const showModalConflickAll = () => {
    setShowAllConflick(true);
  };

  return (
    <div>
      <div className="flex gap-3">
        {currentRole === "Admin" && (
          <>
            <Button
              type={"button"}
              onClick={autoAssignShift}
              className={"w-[150px] group group-hover:text-white"}
              label={
                <div className="flex gap-2 items-center">
                  <MagicSvg
                    className={"group-hover:text-white"}
                    color={"orange-500"}
                  />
                  <h1>Auto Assign</h1>
                </div>
              }
            />

            <button
              onClick={() => {
                setConfirmationDeleteAll(true);
              }}
            >
              <span className="border-[1px] border-black w-[60px] h-[60px] rounded-full flex justify-center items-center">
                <FiTrash2 />
              </span>
            </button>
          </>
        )}

        <button onClick={showModalDownload}>
          <span className="border-[1px] border-black w-[60px] h-[60px] rounded-full flex justify-center items-center  ">
            <FiUpload />
          </span>
        </button>

        {/* SET BORDER TO RED IF ANY CONFLICK AND REPLACEMENT */}
        <button onClick={showModalConflickAll}>
          <span
            className={`border-[1px] ${
              statusBorder ? "border-red-500" : "border-black"
            } w-[60px] h-[60px] rounded-full flex justify-center items-center`}
          >
            <ErrorSvg
              className={`${statusBorder ? "text-red-500" : "text-black"}`}
            />
          </span>
        </button>

        {currentRole === "Admin" && (
          <>
            <button
              onClick={showConfirmPublish}
              className="h-[60px] w-[150px] border-[1px] border-black rounded-md flex gap-2 justify-center items-center"
            >
              <PaperUpload />
              <span>Publish</span>
            </button>

            <Button
              onClick={handleCreateShift}
              type={"button"}
              className={"w-[200px]"}
              label={
                <div className="flex gap-2 items-center">
                  <FiFilePlus />
                  <h1>Created New Shift</h1>
                </div>
              }
              style={"solid"}
            />
          </>
        )}
      </div>

      {/* CREATE SHIFT */}
      {showCreateShift && (
        <ModalDialog
          title={"Create Shift"}
          onClose={onCloseModal}
          className={"h-[700px] w-[700px] "}
        >
          <FormCreateShift setShowCreateShift={setShowCreateShift} />
        </ModalDialog>
      )}

      {/* AUTO ASSIGN SHIFT */}
      {showAutoAssign && (
        <ModalDialog
          title={"Auto Assign"}
          onClose={closeAutoAssignModal}
          className={"h-[700px] w-[1100px] "}
        >
          <AutoAssignShift setShowAutoAssign={setShowAutoAssign} />
        </ModalDialog>
      )}

      {/* DELETE ALL ASSIGN SHFIFT */}
      {confirmationDeleteAll && (
        <ModalConfirmation
          title={"Delete All Assign Shift"}
          headMessage={"This is will be Delete All Your Shift !"}
          onClose={() => {
            setConfirmationDeleteAll(false);
          }}
          onConfirm={confirmDeleteAllAssign}
        />
      )}

      {/* SHOW CONFLICK ALL DATA */}
      {showAllConflick && (
        <ModalDialog
          title={"Conflicts"}
          className={"w-[730px] h-[700px]"}
          onClose={() => {
            setShowAllConflick(false);
          }}
        >
          <ConflickAllData allDate={values.date} />
        </ModalDialog>
      )}

      {modalDownload && (
        <ModalDownloadExcel
          setShowModalDowload={setShowModalDownload}
          listEmployee={[]}
        />
      )}
    </div>
  );
}

export default ButtonAllEmployeeView;
