import React from "react";
import { useSelector } from "react-redux";

import { useExceedingTime } from "services/shiftServices";
import { formatDate } from "utils/common/generateDateRange";
import TabExceedingTime from "components/TabExceedingTime";

function ExceedingTime() {
  const { dataDate } = useSelector((state) => state.employeeView);

  const fromDate = dataDate.length > 0 && formatDate(dataDate[0]);
  const toDate =
    dataDate.length > 0 && formatDate(dataDate[dataDate.length - 1]);

  const { data: overTimeData, isLoading: loadingOverTime } = useExceedingTime({
    fromDate,
    toDate,
  });

  if (loadingOverTime) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {overTimeData.data.length > 0 &&
        overTimeData.data.map((item) => {
          return (
            <TabExceedingTime
              fullName={item.fullName}
              linkImage={item?.photo[0]?.link}
              location={item.location}
              role={item.role}
              durationHour={item?.overTime[0]?.overTimeDuration}
              allDuration={item?.overTime}
            />
          );
        })}
    </div>
  );
}

export default ExceedingTime;
