import { useMutation, useQuery } from "@tanstack/react-query";
import customAxios from "utils/axios";

// const API = ;
const DASHBOARD_API = `${process.env.REACT_APP_API_SCHEDULE}/api/assignshift/dashboard`;
const URL_ASSIGN = `${process.env.REACT_APP_API_SCHEDULE}/api/assignshift`;

export const useGetAllEmployeeAssigned = (props = {}) => {
  const {
    customfromDate,
    customtoDate,
    selectFilterBy,
    location,
    jobPosition,
  } = props;

  // let queryParams;

  const employeeAssigned = async () => {
    const queryParams = new URLSearchParams();

    if (selectFilterBy) queryParams.append("selectFilterBy", selectFilterBy);
    if (customfromDate) queryParams.append("customfromDate", customfromDate);
    if (customtoDate) queryParams.append("customtoDate", customtoDate);
    if (location) queryParams.append("location", location);
    if (jobPosition) queryParams.append("jobPosition", jobPosition);

    const queryString = queryParams.toString();

    const endPoint = queryParams
      ? `${URL_ASSIGN}/employeeWithoutConflik?${queryString}`
      : `${URL_ASSIGN}/employeeWithoutConflik`;

    const resp = await customAxios.get(endPoint);
    return resp.data;
  };

  const queryKey = ["getAssignedEmployee"];
  if (customfromDate && customtoDate) {
    queryKey.push(`customfromDate=${customfromDate}`);
  }
  if (selectFilterBy) queryKey.push(`selectFilterBy=${selectFilterBy}`);
  if (location) queryKey.push(`location=${location}`);
  if (jobPosition) queryKey.push(`jobPosition=${jobPosition}`);

  return useQuery({
    queryKey: queryKey,
    queryFn: employeeAssigned,
    onError: (err) => {
      console.log("Something Wrong ", err);
    },
  });
};

export const usePostAssignShift = ({ onSuccess, onError }) => {
  const assignShift = async (body) => {
    const resp = await customAxios.post(`${URL_ASSIGN}`, body);
    return resp.data;
  };

  return useMutation({
    mutationFn: assignShift,
    onSuccess,
    onError,
  });
};

export const useUpdateAssignShift = (props) => {
  const { uId, onSuccess, onError } = props;

  const updateAssign = async (body) => {
    const resp = await customAxios.put(`${URL_ASSIGN}/${uId}`, body);
    return resp.data;
  };

  return useMutation({
    mutationFn: updateAssign,
    onSuccess,
    onError,
  });
};

export const useGetByIdAssign = ({ uId }) => {
  const getAssignById = async () => {
    const resp = await customAxios.get(`${URL_ASSIGN}/${uId}`);
    return resp.data;
  };

  return useQuery({
    queryFn: getAssignById,
    queryHash: ["getAssignById"],
    onError: (err) => {
      console.log("Something Wrong ", err);
    },
  });
};

export const useDeleteAssignShift = (props) => {
  const { uId, onSuccess, onError } = props;

  const deleteAssignShift = async () => {
    const resp = await customAxios.delete(`${URL_ASSIGN}/${uId}`);
    return resp.data;
  };

  return useMutation({
    mutationFn: deleteAssignShift,
    onSuccess,
    onError,
  });
};

export const useGetAssignShift = () => {
  const getAssign = async () => {
    const resp = await customAxios.get(`${URL_ASSIGN}`);
    return resp.data;
  };

  return useQuery({
    queryKey: ["getAssignShift"],
    queryFn: getAssign,
    onError: (err) => {
      console.log("Something wrong", err);
    },
  });
};

// UPDATE OR PUBLISH DATA
export const usePublishAssignShift = ({ onSuccess, onError }) => {
  const publish = async (body) => {
    const resp = await customAxios.put(`${URL_ASSIGN}/publish`, body);
    return resp.data;
  };

  return useMutation({
    mutationFn: publish,
    onSuccess,
    onError,
  });
};

// GET ALL DATA DASHBOARD
export const useGetAllDashboard = (queryData = {}) => {
  const { fromDate, toDate } = queryData;

  const dashBoard = async () => {
    const queryParams = new URLSearchParams();

    // Hanya tambahkan parameter jika nilainya tidak kosong
    if (fromDate) queryParams.append("fromDate", fromDate);
    if (toDate) queryParams.append("toDate", toDate);

    const queryString = queryParams.toString(); // Hasilkan string query

    // Bangun URL akhir, hanya tambahkan '?' jika queryString tidak kosong
    const url = `${DASHBOARD_API}${queryString ? `?${queryString}` : ""}`;

    try {
      const resp = await customAxios.get(url);
      return resp.data;
    } catch (error) {
      console.log("Something Wrong: ", error);
      throw error;
    }
  };

  const queryKey = ["getDashboard"];
  if (fromDate) queryKey.push(`fromDate=${fromDate}`);
  if (toDate) queryKey.push(`toDate=${toDate}`);

  return useQuery({
    queryKey: queryKey.length > 1 ? queryKey : ["getDashboard"],
    queryFn: dashBoard,
    onError: (err) => {
      console.log("err : ", err);
    },
  });
};

// DELETE ALL ASSIGN SHIFT
export const useDeleteAllAssignShift = ({ onSuccess, onError }) => {
  const deleteAllAssign = async (body) => {
    const resp = await customAxios.delete(`${URL_ASSIGN}/delete`, {
      data: body,
    });

    return resp.data;
  };

  return useMutation({
    mutationFn: deleteAllAssign,
    onSuccess,
    onError,
  });
};
