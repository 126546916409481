import { useMutation, useQuery } from "@tanstack/react-query"
import customAxios from "utils/axios"

const API = `${process.env.REACT_APP_API_SCHEDULE}/api/replacementrequest`

export const useGetDataRequestReplacement = () => {
    const requestReplace = async () => {
        const resp = await customAxios.get(`${API}`)
        return resp.data;
    }

    return useQuery({
        queryKey: ["getRequestReplacement"],
        queryFn: requestReplace,
        onError: (err) => {
            console.log("ERR : ", err)
        }
    })
}

export const useGetRequestByID = ({ uId, onSuccess }) => {
    const requestById = async () => {
        const resp = await customAxios.get(`${API}/${uId}`)

        return resp.data;
    }

    return useQuery({
        queryKey: ["getRequestById"],
        queryFn: requestById,
        onSuccess,
        onError: (err) => {
            console.log("Something Wrong !", err)
        }
    })
}

export const useGetDataRequestById = ({ uId }) => {
    const requestReplace = async () => {
        const resp = await customAxios.get(`${API}/${uId}`)
        return resp.data;
    }

    return useQuery({
        queryKey: ["getRequestByID"],
        queryFn: requestReplace,
        onError: (err) => {
            console.log("ERR : ", err)
        }
    })
}

export const useRequestReplacement = ({ onSuccess, onError }) => {
    const requestReplace = async (body) => {
        const resp = await customAxios.post(`${API}`, body)
        return resp.data;
    }

    return useMutation({
        mutationFn: requestReplace,
        onSuccess,
        onError
    })
}

export const useUpdateRequesReplacement = ({ onSuccess, onError }) => {
    const updateRequest = async (body) => {
        const resp = await customAxios.put(`${API}/replacementRequestStatus`, body)
        return resp.data
    }

    return useMutation({
        mutationFn: updateRequest,
        onSuccess,
        onError
    })
}